.section--cards {
  width: $g-section-width;
  padding: 0;
  margin: 0 auto;
}

.cards__headline {
  font-size: $font--size--2;
  padding-top: $g-spacing-custom-large;
  padding-bottom: $g-spacing-regular;
  font-weight: bold;
  text-align: center;
}

.cards__subline {
  font-size: $font--size--4;
  color: $cards-text-color;
  text-align: center;
  padding-bottom: 0;
}

.card__image {
  float: left;
  display: inline-block;
  padding: 0 $g-spacing-small;
}

.svg-icon {
  width: 3.5em;
}

.svg-icon--smaller {
  width: 3.2em;
}

.cards-wrapper {
  margin-top: $g-spacing-small;
}

.card__block {
  max-width: 80%;
  display: inline-block;
  padding-left: $g-spacing-tiny;
}

.card__title {
  font-size: $font--size--2;
}

.card__text {
  font-size: $font--size--5;
  line-height: $line--height--1;
  color: $cards-text-color;
  padding-top: $g-spacing-smaller;
}

/* media queries */

@include tablet {
  .section--cards {
    width: 80%;
    margin: 0 auto;
  }

  .cards__headline,
  .cards__subline {
    width: 70%;
    margin: 0 auto;
  }

  .cards__headline {
    font-size: $font--size--1;
    line-height: $line--height--1;
  }

  .cards__subline {
    font-size: $font--size--5;
    line-height: $line--height--1;
  }

  .cards-wrapper {
    display: flex;
    flex-flow: row wrap;
    margin-top: 0;
    margin-bottom: $g-spacing-medium;
  }

  .card {
    width: 33%;
    margin-top: $g-spacing-medium;
    padding-left: $g-spacing-medium;
  }

  .card__image {
    padding: 0;
    margin-left: 0;
  }

  .svg-icon {
    width: 2.4em;
  }

  .svg-icon--smaller {
    width: 2em;
  }

  .card__block {
    margin-left: $g-spacing-smaller;
    max-width: 75%;
  }
}

@include desktop {
  .section--cards {
    width: 80%;
    max-width: 1400px;
    margin: 0 auto;
  }

  .cards__headline,
  .cards__subline {
    width: 70%;
    margin: 0 auto;
  }

  .cards__headline {
    font-size: ($font--size--1)*1.5;
    line-height: $line--height--1;
    padding-top: ($g-spacing-custom-large)/2;
  }

  .cards__subline {
    font-size: ($font--size--4)*1.3;
    line-height: $line--height--1;
  }

  .cards-wrapper {
    display: flex;
    flex-flow: row wrap;
    margin-top: 0;
    margin-bottom: $g-spacing-medium;
  }

  .card {
    width: 32%;
    margin-top: $g-spacing-medium;
    padding-left: $g-spacing-small;
  }

  .card__image {
    padding: 0;
    margin-left: 0;
  }

  .svg-icon {
    width: 3em;
  }

  .svg-icon--smaller {
    width: 2.7em;
  }

  .card__block {
    margin-left: $g-spacing-small;
    max-width: 75%;
  }

  .card__text {
    font-size: ($font--size--5)*1.3;
    line-height: $line--height--1;
  }
}

