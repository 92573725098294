@import "../helper/slick.scss";
@import "../helper/slick-theme.scss";

.slider {
  width: 50%;
  margin: 100px auto;
}

.slick-slide {
  margin: 0;
}

.slick-slide img {
  width: 100%;
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

.slick-slide {
  transition: all ease-in-out .3s;
  opacity: .2;
}

.slick-active {
  opacity: .5;
}

.slick-current {
  opacity: 1;
}