$background-color: $g-color-muted;

html {
  height: 100%;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

body {
  background-color: $background-color;
  color: choose-contrast-color($background-color);

  font: 100%/1.5 $g-body-font;
  font-size: $doc-font-size;
  letter-spacing: $doc-letter-spacing;
  line-height: $doc-line-height;
  -webkit-text-size-adjust: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
}