.section--crew {
  padding: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 40em;
  display: flex;
  margin-bottom: $g-spacing-large;
  margin-top: $g-spacing-extra-large;
}

.crew {
  width: 50%;
  max-width: 19em;
  margin: 0 auto;
}

.crew__single {
  position: relative;
}

.crew__single--overlay::after {
  position: absolute;
  content: "";
  background-color: black;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.crew__data {
  color: $g-color-base;
  text-align: center;
  opacity: 0;
  position: absolute;
  width: 100%;
  bottom: $g-spacing-small;
}

.crew__data__name,
.crew__data__position {
  padding-bottom: $g-spacing-tiny;
  z-index: 100000;
}

.crew__single:hover,
.crew__single:active {
  .crew__single--overlay::after {
    opacity: 0.5;
  }

  .crew__data {
    opacity: 1;
  }
}
