/*

This file is generated automatically, changes will be lost on recompile!

Change src/assets/iconfont/_icons.scss instead <3

*/
@font-face {
  font-family: "ikonische-font";
  src: url('../fonts/ikonische-font.eot?#iefix') format('eot'),
  url('../fonts/ikonische-font.woff2') format('woff2'),
  url('../fonts/ikonische-font.woff') format('woff'),
  url('../fonts/ikonische-font.ttf') format('truetype');
}

@mixin icon-styles {
  font-family: "ikonische-font";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%icon {
  @include icon-styles;
}

@function icon-char($filename) {
  $char: "";

  @if $filename == envelop {
  $char: "\E001";
}
  @if $filename == facebook {
  $char: "\E002";
}
  @if $filename == fire {
  $char: "\E003";
}
  @if $filename == info {
  $char: "\E004";
}
  @if $filename == library {
  $char: "\E005";
}
  @if $filename == linkedin {
  $char: "\E006";
}
  @if $filename == location {
  $char: "\E007";
}
  @if $filename == phone {
  $char: "\E008";
}
  @if $filename == sphere {
  $char: "\E009";
}
  @if $filename == twitter {
  $char: "\E00A";
}
  @if $filename == users {
  $char: "\E00B";
}

@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
&:#{$insert} {
  @if $extend {
    @extend %icon;
  } @else {
    @include icon-styles;
  }
  content: icon-char($filename);
}
}

.icon--envelop {
  @include icon(envelop);
}
.icon--facebook {
  @include icon(facebook);
}
.icon--fire {
  @include icon(fire);
}
.icon--info {
  @include icon(info);
}
.icon--library {
  @include icon(library);
}
.icon--linkedin {
  @include icon(linkedin);
}
.icon--location {
  @include icon(location);
}
.icon--phone {
  @include icon(phone);
}
.icon--sphere {
  @include icon(sphere);
}
.icon--twitter {
  @include icon(twitter);
}
.icon--users {
  @include icon(users);
}