footer {
  position: relative;
  margin-top: $g-spacing-extra-large;

  & .l-layout {
    background: $anthracite;
    padding-bottom: $g-spacing-extra-large;

    margin-top: 35px;
  }
}

.footer {
  &__body,
  .social__links,
  a {
    width: 80%;
    margin: 0 auto;
    color: $g-color-base;

    & li {
      list-style: none;
    }

    & p {
      padding: 0;
    }
  }

  & .address__street,
  .address__zip,
  .address__city {
    display: inline-block;
  }
}

.social__links {
  text-align: center;
  padding-top: $g-spacing-small;
  display: none;
}

.social__links__single {
  padding: $g-spacing-extra-large;
  display: inline-block;
  font-size: $font--size--2;
  text-align: center;
}

address {
  font-style: normal;
}

.footer__body {
  margin-top: $g-spacing-extra-large;
  padding-bottom: $g-spacing-medium;
}

.footer__body .card__image {
  float: left;
  display: inline-block;
  padding: 0 $g-spacing-small;
}

.footer__body .svg-icon {
  width: 3em;
}

object.card__image.svg-icon svg {
  fill: $g-color-base;
}

.footer__body__item {
  padding: $g-spacing-small 0;
}

.footer__block {
  max-width: 75%;
  display: inline-block;
  padding-left: $g-spacing-smaller;
}

.footer__border--bg {
  height: 105px;
  background-color: transparent;

}

.footer__border--pattern-1,
.footer__border--pattern-2 {
  background-position: center;
  mix-blend-mode: multiply;
}

.footer__border--pattern-1 {
  height: 70px;
  background-color: #888888;
  background-image: linear-gradient(45deg, #555555 25%, transparent 25%, transparent 75%, #fff 75%, #fff),
  linear-gradient(-45deg, #555555 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-size: 70px 70px;
}

.footer__border--pattern-2 {
  height: 70px;
  background-color: $anthracite;
  background-image: linear-gradient(45deg, $anthracite 25%, transparent 25%, transparent 75%, #555555 75%, #555555),
  linear-gradient(-45deg, $anthracite 25%, transparent 25%, transparent 75%, #555555 75%, #555555);
  background-size: 70px 70px;
}

@include tablet--up {
  .footer__body {
    display: flex;
    flex-flow: row wrap;
    max-width: 37em;
  }

  .footer__body__item {
    width: 50%;
    padding-left: $g-spacing-extra-large;

    &:nth-of-type(1) {
      order: 1;
    }

    &:nth-of-type(2) {
      order: 2;
    }

    &:nth-of-type(3) {
      order: 5;
    }

    &:nth-of-type(4) {
      order: 3;
    }

    &:nth-of-type(5) {
      order: 4;
    }

    &:nth-of-type(6) {
      order: 6;
    }
  }

  .footer__block {
    padding-left: 0;
  }
}
