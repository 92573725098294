.section--cta {
  width: $g-section-width;
  margin: 0 auto;
  padding: $g-spacing-extra-large;
  padding-bottom: 3.5rem;
}

.section--cta__phrase {
  text-align: center;
  font-size: $font--size--3;
  line-height: normal;
}

.section--cta__btn {
  text-align: center;
}

@include tablet {
  .section--cta__phrase {
    width: 60%;
    margin: 0 auto;
  }
}

@include desktop {
  .section--cta {
    max-width: 1400px;
  }

  .section--cta__phrase {
      font-size: ($font--size--3)*1.3;
  }
}
