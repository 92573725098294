.button {
  background: $g-color-base;
  color: choose-contrast-color($g-color-base);
  border: 0;
  padding: $g-spacing-smaller $g-spacing-medium;
  text-transform: uppercase;
  font-weight: bold;
  font-size: $doc-font-size;

  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;

  &:hover,
  &:active {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  &:before {
    position: absolute;
    z-index: -1;
    content: '';
    left: calc(50% - 10px);
    top: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent $g-color-base transparent;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
  }

  &:hover:before,
  &:active:before {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@include desktop {
  .button {
    font-size: ($doc-font-size)*1.3;
  }
}
