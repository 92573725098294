.l-container {
  max-width: $g-max-width;
  margin: 0 auto;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}

.container__legal {
  width: 85%;
  margin: 0 auto;
  padding-top: $g-spacing-extra-large;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}

@include tablet--up {
  .container__legal {
    max-width: $g-max-width/2;
  }
}

.layout-triangle::before,
.layout-triangle--right::before {
  content: '';
  width: 0;
  height: 0;
  border-top: 100vw solid $g-color-base;
  border-right: 100vw solid transparent;
  pointer-events: none;
  position: absolute;
  z-index: -1;
}

.layout-triangle::before {
  left: 0;
  top: 0;
}

.layout-triangle--right::before {
  opacity: 0.25;
  right: 0;
  transform: scaleX(-1)
}

@include desktop {
  .layout-triangle::before,
  .layout-triangle--right::before {
    border-top: 50vw solid $g-color-base;
    border-right: 50vw solid transparent;
  }
}

.l-container--narrow {
  max-width: $g-max-width-narrow;
}

.l-50-50 {
  @include tablet--up {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.l-33-33-33 {
  @include tablet--up {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

section {
  padding: $g-spacing-tiny;
}
