.imprint__padding {
  padding-top: $g-spacing-large;
  padding-bottom: $g-spacing-medium;
}

.imprint__list {
  list-style: none;

  & li:nth-child(5) {
    padding-top: $g-spacing-small;
  }
}

.imprint__list--one-line {
  float: left;
  padding-right: $g-spacing-tiny;
}