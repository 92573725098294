.privacy__padding {
  padding-top: $g-spacing-large;
  padding-bottom: $g-spacing-medium;
  line-height: normal;
}

.privacy__list {
  margin-bottom: $g-spacing-regular;
  padding-left: $g-spacing-large;
}

.single-paragraph {
  padding-top: $g-spacing-large;
}
