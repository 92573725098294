.section--logo__headline {
  width: 70%;
  padding-top: $g-spacing-tiny;
  margin-bottom: -$g-spacing-small;
}

.section--logo__subline {
  font-size: $font--size--5;
  padding-bottom: $g-spacing-small;
  font-weight: bold;
}

.section--logo__headline,
.section--logo__subline {
  padding-left: $g-spacing-medium;
  color: $anthracite;
}

@include tablet {
  .section--logo__headline {
    width: 40%;
    margin-top: $g-spacing-small;
    margin-bottom: -$g-spacing-medium;
  }

  .section--logo__subline {
    font-size: $font--size--3;
  }

  .section--logo__headline,
  .section--logo__subline {
    padding-left: $g-spacing-extra-large;
  }
}

@include desktop {
  .section--logo__headline {
    width: 35%;
    max-width: 530px;
  }

  .section--logo__subline {
    font-size: $font--size--2;
  }
}
