.navigation {
  $background-color: $alt-2;

  &__body {
    margin: 0;
    padding: 0;
  }

  background-color: $background-color;

  .logo img {
    max-width: 42px;
  }

  ul {
    display: inline-block;
    list-style-type: none;
    margin: 0;

    li {
      float: left;
      vertical-align: middle;
      padding: $g-spacing-smaller $g-spacing-medium;
    }
  }
}